import React, { useState, FC, useEffect } from "react";
import axios from "axios";
import { graphql, useStaticQuery } from "gatsby";

import IconButton from "@material-ui/core/IconButton";
import ButtonBase from "@material-ui/core/ButtonBase";

import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";

// ================== Helper functions =======================
import { buildImageObj } from "../../../lib/helpers";
import { imageUrlFor } from "../../../lib/image-url";

import BlockContent from "../../content/block-content";

import * as style from "./product-text.module.scss";

interface IProductText {
  homepage?: boolean;
}
type TTab = {
  tabHead: string;
};
interface SanityProduct {
  title?: string;
  id?: string;
  price?: string;
  volume?: string;
  shippingInfo?: string;
  smallProductDescription?: string;
  tab1: TTab;
  tab2: TTab;
  tab3: TTab;
  _rawTab1: {
    tabBody: any;
    tabHead: string;
  };
  _rawTab2: {
    tabBody: any;
    tabHead: string;
  };
  _rawTab3: {
    tabBody: any;
    tabHead: string;
  };
}
interface LandingPage {
  quickBuyImg?: {
    asset: any;
    alt: string;
    crop: any;
    hotspot: any;
  };
}

interface IQuery {
  sanityProduct: SanityProduct;
  sanityLandingPage: LandingPage;
}

const url_stock = "/.netlify/functions/getStock";

const ProductText: FC<IProductText> = ({ homepage = false }: IProductText) => {
  const { sanityProduct: product, sanityLandingPage }: IQuery = useStaticQuery(productTextQuery);
  const productImage = sanityLandingPage.quickBuyImg;
  const [qty, setQty] = useState<number>(1);
  type viewTabType = "tab1" | "tab2" | "tab3";
  const [viewTab, setViewTab] = useState<viewTabType>("tab1");
  const [stock, setStock] = useState<number>(1);

  useEffect(() => {
    axios
      .get(url_stock)
      .then(function ({ data }) {
        if (Object.keys(data).length >= 0) {
          console.log(data.stock);
          setStock(data.stock);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  const qtyMinus = (): void => {
    if (qty > 1) {
      setQty(qty - 1);
    }
  };

  const qtyPlus = (): void => {
    setQty(qty + 1);
  };
  return (
    <div className={`${style.container} ${homepage && style.container__hp}`}>
      {!homepage && <h3 className={style.productTitle}>{product.title}</h3>}
      <div
        className={`${style.cartOptionsContainer} ${homepage && style.cartOptionsContainer__hp}`}
      >
        <p>{product.volume}</p>
        <p className={style.price}>£{product.price}</p>
        <p className={style.shippingInfo}>{product.shippingInfo}</p>
        <p>{product.smallProductDescription}</p>
        <div className={`${style.qtyAndCart} ${homepage && style.qtyAndCart__hp}`}>
          <div className={`${style.qtySelector} ${homepage && style.qtySelector__hp}`}>
            <IconButton
              className={`${style.qtyBtn} ${homepage && style.qtyBtn__hp}`}
              onClick={qtyMinus}
            >
              <FiMinusCircle />
            </IconButton>
            <p>{qty}</p>
            <IconButton
              className={`${style.qtyBtn} ${homepage && style.qtyBtn__hp}`}
              onClick={qtyPlus}
            >
              <FiPlusCircle />
            </IconButton>
          </div>
          <ButtonBase disabled={stock === 0}>
            {stock > 0 || stock === undefined ? (
              <a
                data-item-id={product.id?.toUpperCase()}
                data-item-price={product.price}
                data-item-url="/product"
                data-item-image={imageUrlFor(buildImageObj(productImage))
                  .width(150)
                  .fit("crop")
                  .url()}
                data-item-name={product.title}
                data-item-quantity={qty}
                data-item-custom1-name="Sending as a gift? Include a message below."
                data-item-custom1-type="textarea"
                className={`${style.addToCartBtn} snipcart-add-item`}
              >
                Add To Basket
              </a>
            ) : (
              <a
                className={`${style.addToCartBtnDisabled} ${
                  homepage && style.addToCartBtnDisabled__hp
                }`}
              >
                Sorry, out of stock
              </a>
            )}
          </ButtonBase>
        </div>
      </div>
      {!homepage && (
        // tabs will be here
        <section className={style.tabContainer}>
          <div className={style.tabHeaderContainer}>
            <h3
              onClick={() => setViewTab("tab1")}
              className={`${style.tabHeader} ${viewTab === "tab1" && style.tabHeader__selected}`}
            >
              {product._rawTab1.tabHead}
            </h3>
            <h3
              onClick={() => setViewTab("tab2")}
              className={`${style.tabHeader} ${viewTab === "tab2" && style.tabHeader__selected}`}
            >
              {product._rawTab2.tabHead}
            </h3>
            <h3
              onClick={() => setViewTab("tab3")}
              className={`${style.tabHeader} ${viewTab === "tab3" && style.tabHeader__selected}`}
            >
              {product._rawTab3.tabHead}
            </h3>
          </div>
          {/* body of the tabs */}
          <div>
            {viewTab === "tab1" && (
              <BlockContent overwriteStyles={style.tabBody} blocks={product._rawTab1.tabBody} />
            )}
            {viewTab === "tab2" && (
              <BlockContent overwriteStyles={style.tabBody} blocks={product._rawTab2.tabBody} />
            )}
            {viewTab === "tab3" && (
              <BlockContent overwriteStyles={style.tabBody} blocks={product._rawTab3.tabBody} />
            )}
          </div>
        </section>
      )}
    </div>
  );
};
export const productTextQuery = graphql`
  query productText {
    sanityProduct {
      id
      smallProductDescription
      shippingInfo
      title
      volume
      price
      _rawTab1
      _rawTab2
      _rawTab3
    }
    sanityLandingPage {
      quickBuyImg {
        asset {
          _id
        }
        crop {
          _key
          _type
          bottom
          left
          right
          top
        }
        hotspot {
          _key
          _type
          height
          width
          x
          y
        }
      }
    }
  }
`;
export default ProductText;
